@import 'common/styles/global';

.tabbed-container__tab {
  &-list {
    display: flex;
    position: relative;
    gap: 16px;
    // margin-top: 25px !important;
    margin-bottom: 20px !important;
    width: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @media screen and (min-width: $desktop) {
      padding: 0rem 25px;
    }

    .swiper-slide {
      width: auto !important;
    }
  }

  &--underline {
    background-color: var(--color-Neutral-3);
    height: 1px;
    position: absolute;
    bottom: 0;
    display: block;
  }
  &--underline--active {
    position: absolute;
    bottom: 0;
    display: block;
    height: 2px;
    border-radius: 500px;
    background-color: var(--color-primary-pure);
    transition: all 300ms;
  }
}
nav.tabbed-container__tab-list.container-md-wide::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
@media screen and (min-width: $desktop) {
  .tabbed-container__tab-list {
    margin-bottom: 20px !important;
    padding: 0;
  }
}

.tab-item {
  outline: 0;
  background-color: transparent;
  border-radius: 8px;
  padding: 16px 8px;
  text-align: left;
  transition: 200ms;

  white-space: nowrap;
  cursor: pointer;
  border: 0;
  border-bottom: 0 solid var(--color-primary-pure);
  color: var(--color-Neutral-4);
  @extend %label-lg;
  font-weight: 400;
  // transition: border-bottom-width 500ms $swift-out, color 500ms $swift-out;

  &:hover {
    color:var(--color-primary-pure)
  }
  &:last-of-type {
    border-right: 0;
  }

  &--active {
    color: var(--color-primary-pure);
    font-weight: 600;

    @media screen and (max-width: $tablet-wide)
    {
      border-bottom: 2px solid var(--color-primary-pure);
      border-radius: 0;
    }
  }
}

@media (min-width: $desktop) {
  .tabbed-container {
    width: 100%;
  }
}
