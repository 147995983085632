@import 'common/styles/global';

.chip {
  padding: 0 16px;
  display: inline-flex;
  height: 40px;
  align-items: center;
  flex-shrink: 0;

  border-radius: 500px;
  border: 1px solid var(--color-Neutral-3);
  cursor: pointer;

  &:hover {
    background-color: var(--color-Neutral-3);
  }

  &--active {
    background-color: var(--color-primary-pure);
    color: var(--color-Neutral-1);
    border-color: var(--color-primary-pure);
    &:hover {
      background-color: var(--color-Neutral-3);
      color: black;
      border-color: var(--color-Neutral-3);
    }
  }
}
